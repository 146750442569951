import React from "react";
import Page from "./../components/Page";
import SEO from "./../components/SEO";
import WithSideNav from "../components/WithSideNav";
import { CardRow } from "../components/Card";
import Section from "../components/Section";

const collaborateCards = [
  {
    title: "Discussion Forum",
    description: "Get help, find collaborators, and engage the community",
    link: "https://community.ohif.org"
  },
  {
    title: "Slack Community",
    description: "Join our Slack to chat with the development team",
    link: "https://join.slack.com/t/cornerstonejs/shared_invite/zt-2c7g8j7ds-qc~hGNmhdxd02O_~cmZuDw"
  },
  {
    title: "Office Hours",
    description: "Sign up for our weekly Zoom meetings open to anyone",
    link: "https://github.com/OHIF/OHIF-Office-Hours"
  }
];
// Add more rows here if needed

const stayUpToDateCards = [
  {
    title: "Newsletter",
    description: "Stay up-to-date with new releases and other changes",
    link: "https://ohif.org/news"
  },
  {
    title: "Roadmap",
    description: "Learn about the next features currently in development",
    link: "https://ohif.org/roadmap"
  },
  {
    title: "Twitter",
    description: "Follow us on Twitter, @ohifViewer",
    link: "https://twitter.com/ohifviewer"
  }
  // Add more rows here if needed
];

const contactCards = [
  {
    title: "Email",
    description:
      "Can't make your question public? Email us at contact@ohif.org",
    link: "mailto:contact@ohif.org",
    linkText: "contact@ohif.org"
  }
  // Add more cards for contact section
];

function CommunityPage() {
  return (
    <Page>
      <SEO title="Community | OHIF" />
      <Section>
        <WithSideNav sideNavType="community" title="Collaborate">
          <div className="text-white">
            Community support and engagement is critical to the long-term
            successful sustainment of open source projects. Our aim is to build
            a community of collaborators to ensure continued support for the
            OHIF software framework. We believe that high-quality software with
            commercially permissive software licenses can have deep impact in
            the medical field by accelerating the transition from code to
            clinic. Below you can find several channels to get in touch with our
            team and the rest of the community.
          </div>
          <div className="flex flex-col space-y-16">
            <div>
              <CardRow cards={collaborateCards} className="sm:w-1/3" />
            </div>
            <div>
              <h1 className="text-4xl text-blue-100">Stay Up-to-date</h1>
              <CardRow cards={stayUpToDateCards} className="sm:w-1/3" />
            </div>
            <div>
              <h1 className="text-4xl text-blue-100">Contact</h1>
              <CardRow cards={contactCards} className="sm:w-1/3" />
            </div>
          </div>
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default CommunityPage;
